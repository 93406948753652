<template>
  <div v-show="showFlag" class="clock-in-activity-rule">
    <div class="content">
      <div class="title">活动规则</div>
      <div class="inner">
        <p>支付200斗币可报名参加活动，获得次日打卡机会；</p>
        <p>
          报名成功后在次日19:00-21:00回到签到页打卡，成功打卡的用户可平分奖池内全部斗币；
        </p>
        <p>
          奖池由前一天参加活动用户的报名斗币组成，当期全部瓜分给用户，未在打卡时间内打卡，报名斗币不退回；
        </p>
        <p>
          每日瓜分斗币数额于当日21:00后开始结算，22:00前自动发放至个人账户，请留意站内信通知，或者打卡记录内查看。
        </p>
      </div>
      <div class="btn-box">
        <button @click="hide">知道了</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showFlag: false
    };
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    }
  }
};
</script>
<style lang="stylus" scoped>
.clock-in-activity-rule
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background rgba(0, 0, 0, .5)
  z-index 11
  .content
    position absolute
    top 50%
    left 50%
    margin -192px 0 0 -142px
    width 284px
    height 384px
    background url(./img/reward-bg.png) no-repeat center center
    background-size 100%
    .title
      padding 18px 0 12px
      text-align center
      font-size 25px
      color #fff
    .inner
      position relative
      height 265px
      line-height 17px
      margin 0 7px 16px
      padding 18px 14px 0 27px
      background #fff
      box-shadow 0px 1px 3px 0px rgba(48, 0, 255, 0.22)
      border-radius 3px
      font-size 12px
      color #2F394F
      p
        position relative
        padding-bottom 16px
        &:after
          position absolute
          top 6px
          left -9px
          display inline-block
          content ""
          width 5px
          height 5px
          background #603BEE
          border-radius 50%
    .btn-box
      text-align center
      button
        display inline-block
        width 105px
        height 32px
        padding 0
        background linear-gradient(180deg, #1A63DB, #2A9AFA)
        box-shadow 0px 1px 9px 0px rgba(6, 6, 6, 0.22)
        border-radius 16px
        border none
        color #fff
</style>
