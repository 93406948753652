<template>
  <div class="clock-in-page">
    <h class="clock-header" :isback="true">
      <span class="name">签到</span>
    </h>
    <div class="content">
      <div class="date-box" :class="{ max: dateBoxExtend }">
        <datepicker
          v-if="clockInHistoryLoading"
          :value="date"
          :inline="true"
          :monday-first="true"
          :language="lang.zh"
          :day-cell-content="dayCellContent"
          wrapper-class="clock-in-date-picker"
          :class="{ mini: !dateBoxExtend }"
          :disabled-dates="disabledDates"
          format="yyyy MM dd"
        />
        <button class="extend" @click="dateMine">
          <i class="cubeic-select" :class="{ rotate: dateBoxExtend }" />
        </button>
      </div>
      <div class="book">
        <div class="l">
          <p class="log">
            已连续签到<b>{{ config.signInNum }}天</b>
            <i @click="showRefWin('rewardRuleWinRef')">?</i>
          </p>
          <p v-show="!config.signType" class="desc">
            点击签到领取奖励，查看今日运势
          </p>
          <p v-show="config.signType" class="desc">
            今日获得
            <span v-text="config.dppRewards" />斗币，
            <span v-text="config.expRewards" />经验值
            <span class="underline" @click="showRefWin('todayLuckyRef')">
              今日运势
            </span>
          </p>
        </div>
        <div class="r">
          <button :class="{ gray: config.signType }" @click="signIn">
            签到
          </button>
        </div>
      </div>
      <div class="activity">
        <div class="title">每日打卡瓜分斗币</div>
        <div v-if="!config.avgDpp" class="yesterday">
          快来瓜分斗币吧~
        </div>
        <div v-else class="yesterday">
          昨天人均瓜分<span v-text="config.avgDpp" />斗币
        </div>
        <div class="cur-count-tips">当前金额（斗币）</div>
        <div class="cur-count-num" v-text="config.sumDpp" />
        <button
          v-if="config.clockType == 0"
          class="join-game-btn"
          @click="joinGame"
        >
          200斗币，参与挑战
        </button>
        <button
          v-if="config.clockType == 1 && config.countDown > 0"
          class="join-game-btn"
          @click="gamePlay"
        >
          倒计时：
          <count-down
            class="timer"
            :tip-text="''"
            :seconds-txt="''"
            :end-text="'立即打卡'"
            :font-size="22"
            :start-time="date.getTime()"
            :end-time="date.getTime() + config.countDown"
            @end_callback="_getClockInConfig"
          />
        </button>
        <button
          v-if="config.clockType == 1 && config.countDown <= 0"
          class="join-game-btn"
          @click="gamePlay"
        >
          立即打卡
        </button>
        <button v-if="config.clockType == 2" class="join-game-btn">
          已打卡
        </button>
        <div class="count-player-box">
          <div class="l">已有<span v-text="config.sumPeople" />人报名</div>
          <div class="m" />
          <div class="r">
            <div class="people-slide-box">
              <cube-slide
                ref="slide"
                :data="gamesPeople"
                :showDots="false"
                :direction="'vertical'"
              >
                <cube-slide-item
                  v-for="(item, index) in gamesPeople"
                  :key="index"
                >
                  {{ item }}
                </cube-slide-item>
              </cube-slide>
            </div>
            报名
          </div>
        </div>
        <div class="setup">
          <div class="setup-title">三步瓜分斗币</div>
          <div class="step">
            <div class="l">
              <img src="./img/dpp.png" class="dpp" alt="dpp" />
              <p>200斗币报名</p>
            </div>
            <div class="m">
              <img src="./img/ca.png" class="ca" alt="日历" />
              <p>次日准时打卡</p>
              <p class="time">09:00-21:00</p>
            </div>
            <div class="r">
              <img src="./img/box.png" class="box" alt="礼盒" />
              <p>瓜分斗币</p>
            </div>
          </div>
          <button class="logs-btn btn" @click="toLogs">打卡记录</button>
          <button class="rule-btn btn" @click="showRefWin('gameRuleRef')">
            玩法详情
          </button>
        </div>
      </div>
    </div>
    <today-lucky ref="todayLuckyRef" :lucky="todayLuckyValue" />
    <reward-rule ref="rewardRuleWinRef" :list="rewardExplain" />
    <activity-rule ref="gameRuleRef" />
    <alert ref="alertRef" @fight="joinGame" />
    <delay ref="delayRef" />
  </div>
</template>
<script>
import h from "@/components/base/header";
import todayLucky from "@/components/clock-in/today-lucky";
import rewardRule from "@/components/clock-in/reward-rule";
import activityRule from "@/components/clock-in/activity-rule";
import alert from "@/components/clock-in/alert";
import delay from "@/components/clock-in/delay";
import Datepicker from "vuejs-datepicker";
import countDown from "@/components/base/countdown/countdown";
import * as lang from "vuejs-datepicker/src/locale";
import { READ_CLOCKIN_TAGS } from "@/api/config";
import { getClockInConfig, clockin, getSigninHistoryList } from "@/api/clockin";
import { mapState } from "vuex";

const date = new Date();

export default {
  components: {
    h,
    todayLucky,
    rewardRule,
    activityRule,
    alert,
    delay,
    Datepicker,
    countDown
  },
  data() {
    return {
      date,
      lang,
      format: "yyyy MM dd",
      disabledDates: {},
      dateBoxExtend: true,
      config: {},
      gamesPeople: [],
      rewardExplain: [],
      todayLuckyValue: {},
      clockInHistoryLoading: false,
      clockInHistoryList: []
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      user: state => state.user.info
    })
  },
  created() {
    this._getClockInConfig();
    this._getSigninHistoryList();
  },
  mounted() {
    this.setReadRuleTags();
  },
  methods: {
    setDateDisable() {
      const regDate = this.user.registerTime.split(" ")[0].split("-");
      const regYear = regDate[0];
      const regMonth = regDate[1];
      const regDay = regDate[2];
      this.disabledDates = {
        to: new Date(regYear, regMonth - 1, regDay),
        from: this.date
      };
    },
    dayCellContent(cell) {
      const curTimestamp = this.date.getTime();
      cell.isFormerly = curTimestamp > cell.timestamp;
      cell.isClockIn = false;
      cell.hasGifts = false;
      const cellDate = new Date(cell.timestamp);
      const year = cellDate.getFullYear();
      const month = cellDate.getMonth() + 1;
      const day = cellDate.getDate();
      const cellDay = `${year}-${month}-${day < 10 ? "0" + day : day}`;
      const len = this.clockInHistoryList.length;
      if (len) {
        for (let i = 0; i < len; i++) {
          const clockInDay = this.clockInHistoryList[i].createTime.split(
            " "
          )[0];
          if (clockInDay == cellDay) {
            cell.isClockIn = true;
          }
        }
      }
      if (this.config.firstTime) {
        const lastClockIn = new Date(this.config.firstTime).getTime();
        const continue7 = this.formatContinueDate(lastClockIn, 6);
        const continue21 = this.formatContinueDate(lastClockIn, 20);
        const continue30 = this.formatContinueDate(lastClockIn, 29);
        if (
          cellDay == continue7 ||
          cellDay == continue21 ||
          cellDay == continue30
        ) {
          cell.hasGifts = true;
        }
      }
      const formerly = cell.isFormerly ? "formerly" : "";
      const clockin = cell.isClockIn ? "clockin" : "";
      const gifts = cell.hasGifts ? "gifts" : "";
      return `<b class='${formerly} ${clockin} ${gifts}'>${cell.date}</b>`;
    },
    formatContinueDate(time, day) {
      const dayTimestamp = time + 1000 * 60 * 60 * 24 * day;
      const date = new Date(dayTimestamp);
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();
      return `${y}-${m}-${d}`;
    },
    dateMine() {
      this.dateBoxExtend = !this.dateBoxExtend;
    },
    setReadRuleTags() {
      localStorage.setItem(READ_CLOCKIN_TAGS, 1);
    },
    async _getClockInConfig() {
      const res = await getClockInConfig();
      if (!res) return;
      this.config = res.object;
      this.rewardExplain = this.config.explain;
      this.gamesPeople = this.config.names;
      this.todayLuckyValue = this.config.todaysLuck;
    },
    async signIn() {
      if (this.signinLoading || this.config.signType) return;
      this.signinLoading = true;
      const params = {
        type: 1,
        day: 1
      };
      const res = await clockin(params);
      this.signinLoading = false;
      if (!res) return;
      this.todayLuckyValue = {
        ...res.object,
        mode: 1
      };
      this.config = {
        ...this.config,
        signType: 1
      };
      this._getSigninHistoryList();
      this.showRefWin("todayLuckyRef");
    },
    showRefWin(ref) {
      this.$refs[ref].show();
    },
    async _getSigninHistoryList() {
      this.clockInHistoryLoading = false;
      const year = this.date.getFullYear();
      const month = this.date.getMonth() + 1;
      const day = this.date.getDate();
      const params = {
        startTime: `${this.user.registerTime.split(" ")[0]}`,
        endTime: `${year}-${month}-${day}`
      };
      const res = await getSigninHistoryList(params);
      this.clockInHistoryLoading = true;
      this.setDateDisable();
      if (!res) return;
      this.clockInHistoryList = res.object;
    },
    async joinGame() {
      if (this.config.userDppIntegral < 200) {
        this.$refs.alertRef.show(1);
        return;
      }
      if (this.clockinLoading) return;
      this.clockinLoading = true;
      const params = {
        type: 2,
        day: 1
      };
      const res = await clockin(params);
      this.clockinLoading = false;
      if (!res) return;
      this.$refs.alertRef.show(0);
      this._getClockInConfig();
    },
    async gamePlay() {
      if (this.config.countDown > 0) {
        this.showRefWin("delayRef");
        return;
      }
      if (this.clockinLoading) return;
      this.clockinLoading = true;
      const params = {
        type: 3,
        day: 1
      };
      const res = await clockin(params);
      this.clockinLoading = false;
      if (!res) return;
      this.$refs.alertRef.countMoney = res.object.sumDpp;
      this.$refs.alertRef.show(2);
      this.config = {
        ...this.config,
        clockType: 2
      };
    },
    toLogs() {
      this.$router.push("/clock-logs");
    }
  }
};
</script>
<style lang="stylus" scoped>
.clock-in-page
  position relative
  background #fff
  overflow hidden
  z-index 10
  .clock-header
    background-color #515FE8
  .date-box
    position relative
    margin-bottom 28px
    background #515FE8
    &.max
      height 370px
    .clock-in-date-picker
      height auto
      overflow hidden
      &.mini
        height 160px
    .extend
      position absolute
      top calc(100% - 1px)
      left 50%
      margin-left -38.5px
      display block
      width 77px
      height 17px
      line-height 17px
      background url(./img/extend.png) no-repeat center center
      background-size 100%
      border none
      padding 0
      color #fff
      i
        display inline-block
        font-size 17px
        &.rotate
          transform rotate(180deg)
  .book
    display flex
    justify-content space-between
    align-items center
    margin 12px 12px 29px
    padding 30px 13px 26px 19px
    background linear-gradient(156deg, #4A5EE7, #6D62EC)
    box-shadow 0px 2px 2px 0px rgba(6, 6, 6, 0.3)
    border-radius 3px
    .l
      .log
        padding-bottom 12px
        font-size 16px
        color #fff
        b
          font-weight bold
          color #FFBE04
        i
          position relative
          top -2px
          display inline-block
          vertical-align middle
          width 15px
          height 15px
          line-height 13px
          border 1px solid #fff
          border-radius 50%
          margin-left 6px
          font-size 10px
          font-style normal
          text-align center
      .desc
        font-size 12px
        color #fff
        span
          color #FFBE04
          &.underline
            text-decoration underline
    .r
      button
        width 92px
        height 32px
        background linear-gradient(180deg, #84AAF1, #6289FF)
        box-shadow 0px 1px 9px 0px rgba(6, 6, 6, 0.22)
        border-radius 16px
        border none
        padding 0
        font-size 18px
        font-weight bold
        color #fff
        &.gray
          background #A3A3A3
  .activity
    position relative
    margin 12px 12px 36px
    padding-top 64px
    background linear-gradient(156deg, #7B8CEF, #6353FC)
    box-shadow 0px 2px 2px 0px rgba(6, 6, 6, 0.3)
    border-radius 3px
    text-align center
    overflow hidden
    .title
      position absolute
      top -13px
      left 50%
      margin-left -105px
      width 211px
      height 53px
      line-height 53px
      background url(./img/title.png) no-repeat center center
      background-size 100%
      font-size 17px
      font-weight bold
      color #6D62EC
    .yesterday
      margin 0 auto
      width 189px
      padding 9px 0
      background #fff
      border-radius 16px
      font-size 15px
      color #6D62EC
      span
        padding 0 7px
        font-size 16px
        color #F37715
    .cur-count-tips
      padding 14px 0 25px
      font-size 15px
      color #FFCE41
    .cur-count-num
      font-size 41px
      font-weight bold
      color #fff
    .join-game-btn
      display flex
      justify-content center
      align-items center
      width 244px
      height 46px
      background linear-gradient(180deg, #84AAF1, #6289FF)
      box-shadow 0px 1px 9px 0px rgba(6, 6, 6, 0.22)
      border-radius 7px
      border none
      margin 22px auto
      font-size 22px
      font-weight bold
      color #fff
      .timer
        position relative
        top 2px
        font-weight bold
    .count-player-box
      display flex
      justify-content center
      align-items center
      font-size 11px
      color #fff
      .l
        span
          color #FFCE41
      .people-slide-box
        display inline-block
        vertical-align middle
        width 86px
        height 15px
        line-height 15px
        color #FFCE41
        overflow hidden
      .m
        width 1px
        height 14px
        background rgba(255, 255, 255, .8)
        margin 0 15px
    .setup
      position relative
      margin 16px 10px
      padding-bottom 10px
      background #fff
      border-radius 7px
      .setup-title
        padding 20px 0 85px
        font-size 13px
        font-weight bold
        color #6C5DFF
      .step
        display flex
        justify-content center
        .l,.m,.r
          position relative
          flex 1
          &:after
            position absolute
            top -30px
            right -15px
            display inline-block
            content ""
            width 29px
            height 23px
            background url(./img/arrow.png) no-repeat center center
            background-size 100%
          p
            font-size 12px
            color #595B7A
          .time
            padding-top 6px
            font-size 10px
          img
            position absolute
            bottom 100%
            left 50%
            transform translateX(-50%)
          .dpp
            width 84px
          .ca
            width 56px
          .box
            width 46px
        .r
          &:after
            display none
      .btn
        position absolute
        top 20px
        display block
        width 66px
        height 24px
        line-height 24px
        padding 0
        border none
        color #fff
      .logs-btn
        left 0
        border-top-right-radius 12px
        border-bottom-right-radius 12px
        background #8F69FA
      .rule-btn
        right 0
        border-top-left-radius 12px
        border-bottom-left-radius 12px
        background #77BEFF
</style>
