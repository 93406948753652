<template>
  <div v-show="showFlag" class="today-lucky">
    <div class="content">
      <div v-show="lucky.mode" class="text-box">
        <div class="title">签到成功</div>
        <div class="desc">斗币+{{ lucky.dpp }}，经验值+{{ lucky.exp }}</div>
      </div>
      <div class="image-box">
        <div class="inner">
          <img :src="lucky.image" alt="运势图" />
        </div>
      </div>
      <div class="bottom">
        <div class="ca">
          <div class="day" v-text="day" />
          <div class="month" v-text="month" />
        </div>
        <div class="adv">
          <div class="theme" v-text="lucky.luck" />
          <div class="desc" v-text="lucky.content" />
        </div>
        <button class="close" @click="hide">
          <i class="cubeic-close" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
const date = new Date();
export default {
  props: {
    lucky: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showFlag: false,
      month: `${date.getFullYear()}.${date.getMonth() + 1}`,
      day: date.getDate()
    };
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    }
  }
};
</script>
<style lang="stylus" scoped>
.today-lucky
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background rgba(0, 0, 0, .5)
  z-index 11
  .content
    position absolute
    top 50%
    left 50%
    width 278px
    height 371px
    padding 6px 6px 17px
    background #fff
    border-radius 6px
    transform translate3d(-50%, -50%, 0)
    .text-box
      position absolute
      bottom 100%
      width 100%
      text-align center
      color #fff
      .title
        font-size 27px
      .desc
        padding 12px 0
        font-size 16px
    .image-box
      width 100%
      height 274px
      line-height 274px
      background #505FE7
      padding 7px
      margin-bottom 15px
      border-radius 6px
      text-align center
      font-size 0
      .inner
        width 100%
        height 100%
        background #fff
        border-radius 6px
    .bottom
      display flex
      align-items center
      .ca
        position relative
        display flex
        flex-direction column
        justify-content center
        align-items center
        flex 0 0 54px
        height 62px
        margin-right 9px
        background #505FE7
        color #fff
        &:before
          position absolute
          display block
          content ""
          top 3px
          right 3px
          bottom 3px
          left 3px
          border 1px solid rgba(255, 255, 255, .5)
        .day
          font-size 32px
        .month
          font-size 10px
      .adv
        color #4A5EE7
        .theme
          font-size 19px
          padding-bottom 12px
        .desc
          font-size 11px
      .close
        position absolute
        left 50%
        bottom -57px
        width 36px
        height 36px
        line-height 36px
        margin-left -18px
        background transparent
        padding 0
        border 1px solid #fff
        border-radius 50%
        text-align center
        i
          font-size 20px
          color #fff
</style>
