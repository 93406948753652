<template>
  <div v-show="showFlag" class="clock-in-alert">
    <div class="content">
      <div class="title">提示</div>
      <div v-if="mode == 0" class="inner">
        <p>成功参与明日打卡挑战赛</p>
        <p>明日19：00-21:00打卡瓜分斗币</p>
        <p>记得准时打开哟~</p>
      </div>
      <div v-if="mode == 1" class="inner">
        <p>斗币不足</p>
        <p>观看直播参与互动可以</p>
        <p>免费获得斗币哦~</p>
      </div>
      <div v-if="mode == 2" class="inner">
        <p>打卡成功</p>
        <p>今日可瓜分斗币：{{ countMoney }}</p>
        <p>后期注意查收</p>
      </div>
      <div class="btn-box">
        <button v-if="mode == 2" @click="continueFight">继续挑战</button>
        <button v-else @click="hide">知道了</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showFlag: false,
      mode: -1,
      countMoney: 0
    };
  },
  methods: {
    show(mode) {
      this.mode = mode;
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
      this.mode = -1;
    },
    continueFight() {
      this.$emit("fight");
      this.hide();
    }
  }
};
</script>
<style lang="stylus" scoped>
.clock-in-alert
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background rgba(0, 0, 0, .5)
  z-index 11
  .content
    position absolute
    top 50%
    left 50%
    margin -129px 0 0 -142px
    width 284px
    height 258px
    background url(./img/alert-bg.png) no-repeat center center
    background-size 100%
    .title
      padding 18px 0 12px
      text-align center
      font-size 25px
      color #fff
    .inner
      position relative
      height 130px
      margin 0 7px 16px
      padding-top 36px
      background #fff
      box-shadow 0px 1px 3px 0px rgba(48, 0, 255, 0.22)
      border-radius 3px
      text-align center
      font-size 13px
      color #2F394F
      &:after
        position absolute
        top 6px
        right 6px
        display inline-block
        content ""
        width 5px
        height 5px
        background #603BEE
        border-radius 50%
      p
        padding-bottom 9px
    .btn-box
      text-align center
      button
        display inline-block
        width 105px
        height 32px
        padding 0
        background linear-gradient(180deg, #1A63DB, #2A9AFA)
        box-shadow 0px 1px 9px 0px rgba(6, 6, 6, 0.22)
        border-radius 16px
        border none
        color #fff
    .tips
      padding-top 11px
      text-align center
      font-size 11px
      color #A2A2A2
</style>
