<template>
  <div v-show="showFlag" class="clock-in-reward-rule">
    <div class="content">
      <div class="title">签到奖励说明</div>
      <ul class="list">
        <li v-for="(item, inx) in list" :key="inx" class="item">
          <div>
            <span class="count">每签到{{ item.day }}次</span>
            <span class="dpp">
              <img src="./img/dpp.png" alt="dpp" />+{{ item.dpp }}
            </span>
            <span class="exp">
              <img src="./img/exp.png" alt="exp" />最高+{{ item.exp }}
            </span>
          </div>
        </li>
      </ul>
      <div class="btn-box">
        <button @click="hide">知道了</button>
      </div>
      <p class="tips">
        坚持签到，获取丰富奖励哦~
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      showFlag: false
    };
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    }
  }
};
</script>
<style lang="stylus" scoped>
.clock-in-reward-rule
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background rgba(0, 0, 0, .5)
  z-index 11
  .content
    position absolute
    top 50%
    left 50%
    margin -192px 0 0 -142px
    width 284px
    height 384px
    background url(./img/reward-bg.png) no-repeat center center
    background-size 100%
    .title
      padding 18px 0 12px
      text-align center
      font-size 25px
      color #fff
    .list
      .item
        position relative
        display flex
        align-items center
        height 52px
        margin 0 10px 13px
        padding-left 16px
        background #fff
        box-shadow 0px 1px 3px 0px rgba(48, 0, 255, 0.22)
        border-radius 3px
        font-size 13px
        color #2F394F
        &:after
          position absolute
          top 6px
          right 6px
          display inline-block
          content ""
          width 5px
          height 5px
          background #603BEE
          border-radius 50%
        &:nth-of-type(2)
          &:after
            background #F93380
        &:nth-of-type(3)
          &:after
            background #CC3BFD
        &:nth-of-type(4)
          &:after
            background #00B8CB
        .count
          display inline-block
          width 72px
        .dpp,.exp
          padding-right 16px
          color #F37715
          img
            width 17px
            vertical-align middle
            margin-right 10px
        .exp
          color #F37715
    .btn-box
      text-align center
      button
        display inline-block
        width 105px
        height 32px
        padding 0
        background linear-gradient(180deg, #1A63DB, #2A9AFA)
        box-shadow 0px 1px 9px 0px rgba(6, 6, 6, 0.22)
        border-radius 16px
        border none
        color #fff
    .tips
      padding-top 11px
      text-align center
      font-size 11px
      color #A2A2A2
</style>
