<template>
  <div v-show="showFlag" class="clock-in-delay-alert" @click.self="hide">
    <div class="content">
      <div class="icon">
        <img src="./img/delay.png" alt="警告" />
      </div>
      <p>来早了哦</p>
      <p>还没到时间打卡呢</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showFlag: false,
      mode: 0
    };
  },
  methods: {
    show(mode) {
      this.mode = mode;
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    }
  }
};
</script>
<style lang="stylus" scoped>
.clock-in-delay-alert
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background rgba(0, 0, 0, .5)
  z-index 11
  .content
    position absolute
    top 50%
    left 50%
    margin -129px 0 0 -108px
    width 216px
    height 258px
    background #fff
    border-radius 3px
    text-align center
    .icon
      padding 44px 0 35px
      img
        width 69px
    p
      padding-bottom 12px
      font-size 17px
      color #2F394F
</style>
